.cart-items {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 20rem;
    overflow: auto;
}

.total {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.5rem;
    margin: 1rem 0;
}

.actions {
    text-align: center;
}

.actions button {
    font: inherit;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #FF2625;
    padding: 0.5rem 2rem;
    border-radius: 25px;
    margin-left: 1rem;
}

.actions button:hover,
.actions button:active {
    background-color: #c61515;
    border-color: #c61515;
    color: white;
}

.actions .button--alt {
    color: #8a2b06;
}

.actions .button {
    background-color: #8a2b06;
    color: white;
}